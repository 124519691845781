/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-06-29",
    versionChannel: "nightly",
    buildDate: "2024-06-29T00:05:37.413Z",
    commitHash: "948987b7438ff5ab085f919df28742d301e056db",
};
